import {CSSProperties, PropsWithChildren, ReactNode} from 'react'
import { ComponentEuiFlexItem, ComponentEuiPage, ComponentEuiPageBody, ComponentEuiPageSidebar,
    ComponentEuiProgress, ComponentNavPagePagination, ComponentPageErrorPrompt
} from 'Component'
import { HookPageHeaderHeight, HookPageViewport } from 'Hook'
import { TypeControlPageViewport, TypeHttpLoadable,
    TypeNav, TypeNavHttpControl, TypePageHeaderHeight, TypeWebSocketControl } from 'Type'
import {ModulePageContextNavMeta, ModulePageHeader, ModulePageLoading } from 'Module'

const bodyPanelStyle :CSSProperties = {
    height: '100%',
    position: 'relative'
}

type Props = {
    control :TypeNavHttpControl<any>
    sidebarContent? :ReactNode
    sidebarPaddingSize? :'none' | 'xs' | 's' | 'm' | 'l' | 'xl'
    toLoad? :TypeHttpLoadable[],
    paginationControl? :TypeNavHttpControl<any>
    socketControl? :TypeWebSocketControl<any>
    rightActions? :ReactNode
    hasBreadcrumbs? :boolean
    prependPath? :string
}

const heightStyle = {height: 'calc(100% - 42px)',
fallbacks: [
    { height: '-moz-calc(100% - 42px)' },
    { height: '-webkit-calc(100% - 42px)' },
    { height: '-o-calc(100% - 42px)' }
]}

const ModulePage = ({control, sidebarContent, sidebarPaddingSize, toLoad = [], paginationControl, socketControl, rightActions, hasBreadcrumbs = true, prependPath, children} :PropsWithChildren<Props>) => {

    const paginationNav :TypeNav<any> | undefined = paginationControl?.res?.data
    const isPageable :boolean = Boolean(paginationNav?.data.page)
    const isLoading :boolean = paginationControl?.isLoading ?? false

    const viewport :TypeControlPageViewport = HookPageViewport()
    const headerHeight :TypePageHeaderHeight = HookPageHeaderHeight({control, hasBreadcrumbs})
    const pageHeight :number = viewport.height - headerHeight.headerHeight

    const error :string | undefined = control?.res?.data?.error?.message || control?.res?.error

    return <ModulePageContextNavMeta>
        <ModulePageHeader control={control} hasBreadcrumbs={hasBreadcrumbs && headerHeight.hasBreadcrumbs} rightActions={rightActions}/>
        <ComponentEuiPage height={pageHeight} paddingSize={'none'}>
            {(error) ? <ComponentPageErrorPrompt title={error}/> :
                <ModulePageLoading toLoad={toLoad}>
                    {sidebarContent && <ComponentEuiPageSidebar borderRight paddingSize={sidebarPaddingSize}>{sidebarContent}</ComponentEuiPageSidebar>}
                    <ComponentEuiPageBody panelled panelProps={{hasShadow: false, style: bodyPanelStyle}}>
                        <div style={{width: '100%', overflowY: 'auto', overflowX: 'hidden', ...heightStyle}}>
                            {children}
                        </div>
                        <div style={{
                            position: 'absolute',
                            bottom: 2,
                            width: '100%',
                            zIndex: 99999,
                            backgroundColor: 'white',
                            borderTop: '1px solid #cdd3df'
                        }}>
                            {isPageable && paginationNav && !isLoading && <ComponentEuiFlexItem>
                              <ComponentNavPagePagination nav={paginationNav} socketControl={socketControl} prependPath={prependPath}/>
                            </ComponentEuiFlexItem>}
                            {isLoading && <ComponentEuiFlexItem>
                              <ComponentEuiProgress color={'primary'}/>
                            </ComponentEuiFlexItem>}
                        </div>

                    </ComponentEuiPageBody>
                </ModulePageLoading>
            }
        </ComponentEuiPage>
    </ModulePageContextNavMeta>
}

export default ModulePage
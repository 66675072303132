import { useNavigate } from 'react-router-dom'

import {TypeEuiBreadcrumb, TypeNav, TypeNavHttpControl } from 'Type'
import { ComponentEuiBreadcrumbs, ComponentEuiIcon, ComponentEuiLoadingSpinner } from 'Component'

type Props = {
    control :TypeNavHttpControl<any>
    prependPath? :string
}

const hasLength = (str :string) => str.length > 0

const getPathParts = (nav? :TypeNav<any>) :string[] =>
    nav?.path.split('?')[0].split('/').filter(hasLength) ?? []

const ModulePageHeaderBreadcrumbs = ({ control, prependPath } :Props) => {

    const homePath :string = `${prependPath}/` ?? '/'

    const nav :TypeNav<any> | undefined = control.res?.data
    const isLoading :boolean = control.isLoading

    const navigate = useNavigate()
    const pathParts = getPathParts(nav)
    const breadcrumbs :TypeEuiBreadcrumb[] = [];

    const buildHomeBreadcrumb = () :TypeEuiBreadcrumb => ({
        text: <span style={{padding: '0px 4px'}}>{(isLoading) ? <ComponentEuiLoadingSpinner/> : <ComponentEuiIcon type='home'/>}</span>,
        truncate: false,
        onClick: async () => { navigate(homePath) }
    })

    const buildPartBreadcrumb = (i :number) :TypeEuiBreadcrumb => ({
        text: <span style={{padding: '0px 4px'}}>{pathParts[i].toLowerCase()}</span>,
        truncate: false,
        onClick: (i === pathParts.length - 1) ? undefined : () => { navigate(`${homePath}${pathParts.slice(0, i + 1).join('/')}`) },
        color: (i === pathParts.length - 1) ? 'primary' : undefined,
    })

    breadcrumbs.push(buildHomeBreadcrumb())
    for (let i = 0; i < pathParts.length; i++) breadcrumbs.push(buildPartBreadcrumb(i))

    return <div style={{paddingLeft: 14, width: '100%'}}><ComponentEuiBreadcrumbs breadcrumbs={breadcrumbs} type={'page'} max={0}/></div>
}

export default ModulePageHeaderBreadcrumbs